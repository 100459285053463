
  
  .price-calculator-old{
    letter-spacing: 0;
    font-size: 14px;
    margin-bottom: 25px;
    .d-none{
        display: none !important;
    }
    .switch {
        position: relative;
        display: inline-block;
        input{
          display: none;
        }
        .slider{
          position: reative;
          background: #CECECE;
          border-radius: 7px;
          cursor: pointer;
          transition: 0.2s;
          width: 31px;
          height: 12px;
          display: block;
          &:before{
            position: absolute;
            content: "";
            width: 20px;
            height: 20px;
            background-color: #FF9419;
            border-radius: 100%;
            transform: translateX(0px);
            transition: 0.2s;
            -webkit-transform: translateX(0px);
            -moz-transform: translateX(0px);
            -ms-transform: translateX(0px);
            -o-transform: translateX(0px);
            -webkit-border-radius: 100%;
            -moz-border-radius: 100%;
            -ms-border-radius: 100%;
            -o-border-radius: 100%;
            top: -5px;
            left: -7px;
          }
          i{
            color: #9E9D9C;
            font-weight:600;
            font-style: normal;
            position:absolute;
            top:-4px;
            &:first-child{
              left:-79px;
              color: #42403E;
            }
            &:last-child{
              right:-60px;
            }
          }
          
        }
        input{
            &:checked{
              + .slider{
                &:before{
                   transform: translateX(18px);
                   -webkit-transform: translateX(18px);
                   -moz-transform: translateX(18px);
                   -ms-transform: translateX(18px);
                   -o-transform: translateX(18px);
                }
                i{
                  color: #42403E;
                  &:first-child{
                    color: #9E9D9C;
                  }
                }
              }
            }
        }
        
    }

    .container{
        margin:auto;
        padding: 0 15px;
        width: 100%;
        @media screen and (min-width: 1200px){
            width: 1170px;
        }
    }
    h2{
        font-size:28px;
        font-weight:700;
        color:#000;
        margin-bottom:8px;
        line-height:1;
    }
    .section-head{
        text-align: center;
        p{
            font-size:18px;
            margin:0 0 24px 0;
        }
    }
    .billing-option{
        display:flex;
        align-items:center;
        justify-content: center;
        margin-left: 67px;
        margin-bottom: 27px;
        >div{
            position: relative;
            display: block;
            width: 104px;
        }
        p{
            border: 1px solid #FF9419;
            border-radius: 14px;
            color: #FF9419;
            font-weight: 600;
            font-size: 14px;
            margin:0;
            min-width:113px;
            padding:6px 10px;
            line-height:1;
            margin-top: -8px;
        }
    }
    .company-employee{
        max-width:765px;
        z-index: 1;
        position: relative;
        margin:auto;
        p{
            font-size:16px;
            color: #9E9D9C;
            font-weight:600;
            margin:0;
        }
    }
    .time-tracking{
        display:flex;
        margin:0 -16px;
        margin-top:65px;
        .card{
          padding:0 16px;
          max-width:33.3%;
          height: auto;
          
          .card-inner{
            background: rgba(255, 148, 25, 0.06);
            border-radius: 16px;
            padding:0 20px 40px;
            height: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: space-between;
          }
          .card-head{
            display:flex;
            justify-content: space-between;
            margin-bottom:22px;
            svg{
              color:#FFA947;
            }
            .card-title{
              color: #585858;
              font-weight: 700;
              font-size: 20px;
              padding-top:30px;
              @media screen and (max-width: 991px){
                    font-size: 17px;
                }
            }
          }
          .category{
            position:relative;
            min-width:98px;
            height:115px;
            z-index:1;
            text-align: center;
            display: flex;
            flex-direction: column;
            justify-content: center;
            &:before{
              content:"";
              position:absolute;
              width:13px;
              height:7px;
              background-image: url("data:image/svg+xml,%0A%3Csvg width='11' height='10' viewBox='0 0 11 10' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M10.0109 9.09576L0.529425 9.01399L10 3.29985e-08L10.0109 9.09576Z' fill='%23FFDEBA'/%3E%3C/svg%3E%0A");
              background-repeat: no-repeat;
              top: -7px;
              left: -9px;
            }
            .category-name{
              position: relative;
              top: -25px;
            }
            img{
              width:25px;
              margin-bottom:4px;
              margin-left:auto;
              margin-right:auto;
            }
            P{
              color:#fff;
              font-weight: 400;
              font-size: 18px;
              margin:0;
            }
            svg{
              position: absolute;
              left: 0px;
              z-index: -1;
              top: -7px;
            }
          }
          ul{
            list-style: none;
            padding:0;
            margin:0 0 32px 0;
            li{
              color: #585858;
              font-weight: 400;
              font-size: 18px;
              line-height: 25px;
              position:relative;
              padding-left:34px;
              font-weight: 400;
              &:not(:last-child){
                margin-bottom:12px;
              }
              &:after{
                content:"";
                position:absolute;
                width:24px;
                height:24px;
                background-image: url("data:image/svg+xml,%0A%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12 24C5.3726 24 0 18.6273 0 12C0 5.37268 5.37268 0 12 0C18.6273 0 24 5.37268 24 12C24 18.6273 18.6273 24 12 24ZM7.52564 11.7084C7.00936 11.2208 6.19561 11.2442 5.70804 11.7601C5.22045 12.2764 5.24377 13.0902 5.76005 13.5777L9.61704 17.2208C10.1686 17.7415 11.049 17.6743 11.5148 17.0752L17.5149 9.36088C17.9508 8.80035 17.8497 7.99259 17.2892 7.55644C16.7287 7.12058 15.9209 7.22162 15.4851 7.78214L10.3539 14.3793L7.52564 11.7084Z' fill='%2329A76A'/%3E%3C/svg%3E%0A");
                background-repeat: no-repeat;
                background-size: 100%;
                left:0;
                top:0
              }
            }
            @media screen and (max-width: 991px){
                li{
                    font-size: 17px;
                }
            }
          }
          span{
            display: block;
            color: #585858;
            font-weight: 700;
            font-size: 24px;
            line-height: 30px;
            text-align: center;
          }
          .button-row{
            margin-top:32px;
            .btn{
              font-weight: 700;
              font-size: 18px;
              max-width:165px;
              margin:auto;
              height:48px;
              line-height:48px;
              padding:0 5px;
              box-shadow:none;
            }
          }
          &:nth-child(2){
            .category{
              &:before{
                 background-image: url("data:image/svg+xml,%0A%3Csvg width='11' height='10' viewBox='0 0 11 10' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M10.0109 9.09576L0.529425 9.01399L10 3.29985e-08L10.0109 9.09576Z' fill='%23FFE297'/%3E%3C/svg%3E%0A");
                background-repeat: no-repeat;
              }
              svg{
                color: #FFBF19;
              }
            }
          }
        }
        @media screen and (max-width: 991px){
            margin: 0px -10px !important;
            justify-content: center !important;
            flex-wrap: wrap;
            .card{
                padding: 0 10px !important;
                max-width: 50% !important;
                flex: 0 0 50% !important;
                margin-bottom: 25px;
            }
        }
          @media screen and (max-width: 767px){
            flex-direction: column !important;
            margin: 0 !important;
            .card{
                padding: 0px !important;
                max-width: 100% !important;
                flex:  0 0 100% !important;
                width: 100% !important;
                margin-bottom: 10px !important;
            }
        }
    }
  
@mixin rangeThumb {
    width: 28px;
    height: 28px;
    margin: -8px 0  0;
    border-radius: 50%;
    background: #FFA947 ;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.2);
    border:4px solid #fff;
    cursor: pointer;
    position: relative;
    top: -4px;
    z-index: 2;
}

@mixin rangeTrack {
    width: 100%;
    height: 8px !important;
    background: rgba(104, 103, 102, 0.16);
}
    
    .range {
        position: relative;
        width: 100%;
        height: 5px;
        margin-top: 20px;
    }

    .range input {
        width: 100%;
        position: absolute;
        top: 2px;
        height: 0;
        -webkit-appearance: none;

        // Thumb
        &::-webkit-slider-thumb {
            -webkit-appearance: none; // needed again for Chrome & Safari
            @include rangeThumb;
        }

        &::-moz-range-thumb {
            @include rangeThumb;
        }

        &::-ms-thumb {
            @include rangeThumb;
        }

        // Track
        &::-webkit-slider-runnable-track {
            border-radius: 6px;
            @include rangeTrack;
            //background: linear-gradient(to right, #FFA947 0%, #FFA947 16.66%, #68676629 16.66%, #68676629 100%) !important;
        }

        &::-moz-range-track {
            @include rangeTrack;
        }

        &::-ms-track {
            @include rangeTrack;
        }

        &:focus { // override outline/background on focus
            background: none;
            outline: none;
        }

        &::-ms-track { // A little somethin' somethin' for IE
            width: 100%;
            cursor: pointer;
            background: transparent;
            border-color: transparent;
            color: transparent;
        }
        
    }
// Labels below slider
    .range-labels {
        // margin: 18px -41px 0;
        padding: 0;
        list-style: none;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left: -65px;
        margin-right:-65px;
        @media screen and (max-width: 767px){
            margin-left: -7%;
            margin-right:-7%;
        }
        li {
            position: relative;
            float: left;
            width: 120px;
            text-align: center;
            color: #9E9D9C;
            font-weight: 600;
            font-size: 14px;
            cursor: pointer;
            width: 20%;
            &:not(:last-child):after {
                position: absolute !important;
                top: -25px !important;
                right: 0 !important;
                left: 0;
                margin:0 auto;
                content: "" !important;
                width: 3px !important;
                height: 100% !important;
                background: #fff !important;
                z-index: 0;
            }
            &:first-child{
            //   width:70px;
                &::after{
                    display: none !important;
                }
            
            }
            // &:nth-child(2){
            //   width:111px;
            // }
            // &:nth-child(3){
            //    width:126px;
            // }
            // &:nth-child(4){
            //    width:124px;
            // }
            // &:nth-child(5){
            //    width:163px;
            // }
            // &:nth-child(6){
            //    width:70px;
            // }
        }

        .active {
            color: #FFA947;
        }

        .selected::before {
            background: #FFA947;
        }

        .active.selected::before {
            display: none;
        }
    }
    
}
